import Swal from "sweetalert2";
<template>
  <v-app style="background: transparent">
        <v-card>
            <v-card-title>
                Mis contratos realizados
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>
            <v-progress-linear v-if="loadingData"
                               indeterminate
            ></v-progress-linear>
            <div v-if="!loadingData">
                <v-tabs
                        v-model="tab"
                        background-color="primary"
                        centered
                        dark
                        icons-and-text>
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab v-for="(status ,i) in statusList" :key="i" :href="`#tab-${i}`">
                        {{status.nombre}}
                        <v-icon class="mr-1">{{statusIcon[i]}}</v-icon>
                    </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(status ,i) in statusList"
                                :key="i"
                                :value="`tab-${i}`">
                        <v-card flat>
                            <v-data-table
                                    class="elevation-1"
                                    :loading="loading" loading-text="Loading... Please wait"
                                    :headers="headers"
                                    :items="getData(status.id)"
                                    :search="search">
                                <template v-slot:item.pasos="{ item }">
                                    <v-chip color="green" dark>{{ item.pasos }}</v-chip>
                                </template>
                                <template v-slot:item.actions="{ item }">


                                    <v-tooltip bottom v-if="item.estado !==  completed && user.infopago === payReq">
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                    color="blue darken-2"
                                                    small
                                                    class="mr-2"
                                                    @click="pay(item)">
                                                fa fa-credit-card
                                            </v-icon>
                                        </template>
                                        <span>Pagar contrato</span>
                                    </v-tooltip>


                                    <v-tooltip bottom v-else-if="item.estado ===  completed">
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                    color="green darken-2"
                                                    small
                                                    class="mr-2"
                                                    @click="open(item.producto.contrato.path)"
                                            >
                                                fa fa-download
                                            </v-icon>
                                        </template>
                                        <span>Visualizar contrato en nueva ventana</span>
                                    </v-tooltip>

                                    <v-tooltip bottom v-else-if="user.infopago !== payReq">
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                    color="yellow darken-2"
                                                    small
                                                    class="mr-2"
                                                    @click="pay(item)"
                                            >
                                                fa fa-check
                                            </v-icon>
                                        </template>
                                        <span>Acreditar contrato a plan vigente</span>
                                    </v-tooltip>


                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on"
                                                    color="orange darken-2"
                                                    small
                                                    @click="confirmDelete(item)">
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                        <span>Eliminar contrato</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <v-row align="end">
                    <v-col cols="12" sm="4"></v-col>
                    <v-col cols="12" sm="4">
                        <div class="align-content-xl-end">
                            <v-btn @click="goNew" outlined x-large color="primary" dark>¡Crea tu nuevo contrato ahora!
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4"></v-col>

                </v-row>
            </div>

            <b-modal id="modal-1" title="Previsualización del contrato" style="width: auto" size="xl">
                <div class="my-4" id="templatePreview"></div>
            </b-modal>
            <v-dialog v-model="dialog"
                      max-width="290">
                <v-card>
                    <v-card-title class="headline">Eliminar Contrato</v-card-title>
                    <v-card-text>
                        Se eliminará el contrato
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1"
                               text
                               @click="deleteItem()">
                            Aceptar
                        </v-btn>
                        <v-btn color="green darken-1"
                               text
                               @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                    v-model="payDialog"
                    max-width="500">
                <v-card>
                    <v-card-title class="headline">Se requiere cancelar el valor del contrato</v-card-title>

                    <v-card-text>
                        Actualmente no cuenta con una subscripcion para generar contratos, puede adquirir un plan o
                        pagar este contrato en específico.
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="green darken-1"
                                text
                                @click="openPayment">
                            Pagar contrato
                        </v-btn>

                        <v-btn
                                color="green darken-1"
                                text
                                @click="navigate('/plan')">
                            Subscribirse
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-app>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import service from "@/service/transaction.service"
    import authService from "@/service/login.service"
    import catalogueService from "@/service/catalogue.service"
    import router from "../../../router";
    import Swal from "sweetalert2";

    const COMPLETEINFO = "COMPLETA";
    const PAYREQ = "PagoRequerido";
    const PENDING = "PENDIENTE";
    const COMPLETED = "Pagado";

    export default {
        name: "TransactionHistory",
        data() {
            return {
                payReq: PAYREQ,
                pending: PENDING,
                completed: COMPLETED,
                dialog: false,
                payDialog: false,
                loading: false,
                search: '',
                tab: 'tab-0',
                statusList: [],
                statusIcon: ['fa fa-list', 'fa fa-play-circle', 'fa fa-tasks', 'fa fa-archive'],
                allTabItems: {
                    id: 0,
                    nombre: "Todos"
                },
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        sortable: false,
                        value: 'producto.contrato.nombre',
                    },
                    {
                        text: 'Descripcion',
                        align: 'left',
                        sortable: false,
                        value: 'descripcion',
                    },
                    {
                        text: 'Precio',
                        sortable: true,
                        value: 'valores.total'
                    },
                    {
                        text: 'Fecha de creación',
                        align: 'left',
                        sortable: false,
                        value: 'fechaingreso',
                    },
                    {
                        text: 'Estado',
                        sortable: true,
                        value: 'estado',
                    },
                    {text: 'Actions', value: 'actions', sortable: false},


                ],
                contracts: [],
                currentItem: {},
                loadingData: false,
                user: null
            }
        },
        methods: {
            pay(item) {
                this.currentItem = item;
                if (item.placetopay && item.placetopay.object && item.placetopay.estado === PENDING) {
                    service.initPaymentModal();
                    service.openPaymentModal(item.placetopay.object.processUrl);
                    return;
                }

                if (this.user.infousuario === COMPLETEINFO) {
                    if (this.user.infopago === PAYREQ) {
                        this.payDialog = true;
                    } else {
                        Swal.fire({
                            title: "Contratos disponibles",
                            text: "¿Desea acreditarse este contrato?",
                            icon: "question",
                            showCloseButton: true,
                            showCancelButton: true,
                            confirmButtonText: '<i class="fa fa-check"></i> SI',
                            cancelButtonText: '<i class="fa fa-times"></i> NO',
                            preConfirm: () => {
                                service.pay({
                                    transaccion_id: item.id
                                }).then((result) => {
                                    Swal.fire({
                                        title: "!Se ha acreditado correctamente!",
                                        text: result.data.mensaje,
                                        icon: "success",
                                        showCloseButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: '<i class="fa fa-print"></i> Visualizar contrato',
                                        cancelButtonText: '<i class="fa fa-envelope"></i> Enviar por correo',
                                        onClose: () => {
                                            this.initData();
                                        }
                                    })
                                })
                            }
                        })
                    }
                } else {
                    Swal.fire({
                        title: "",
                        text: "Por favor complete sus datos terminar la transacción",
                        icon: "warning",
                        confirmButtonClass: "btn btn-primary",
                        preConfirm: () => {
                            this.navigate({
                                name: 'profile', params: {
                                    service: 'PAGO DE CONTRATO',
                                    detail: this.currentItem
                                }
                            })
                        }
                    });
                }
            },
            navigate(route) {
                router.push(route)
            },
            openPayment() {
                this.$router.push({
                    name: 'preview', params: {
                        service: 'PAGO DE CONTRATO',
                        detail: this.currentItem
                    }
                })

            },
            open(link) {
                window.open(link ? link : 'https://www.google.com', '_blank')
            },
            preview(item) {
                setTimeout(() => {
                    const content = document.getElementById("templatePreview");
                    content.innerHTML = item.preview;
                }, 10);
            },
            confirmDelete(item) {
                this.dialog = true;
                this.currentItem = item;
            },
            deleteItem() {
                this.dialog = false;
                this.loading = true;

                service.delete(this.currentItem.id).then(() => {
                    this.contracts.forEach(data => {
                        data.contracts.forEach((value, index) => {
                            if (value.id === this.currentItem.id) {
                                data.contracts.splice(index, 1);
                            }
                        })
                    });
                    this.loading = false;
                    this.currentItem = {}
                })
            },
            getData(statusId) {
                const data = this.contracts.filter(x => {
                    return x.statusId === statusId;
                });
                return data[0] ? data[0].contracts : []
            },
            initData() {
                this.contracts = [];
                this.loadingData = true;
                this.loading = true;
                catalogueService.getCatalogue('estados').then(x => {
                    this.statusList = [this.allTabItems, ...x.data.data];
                    this.tab = this.getCurrentTab();
                    this.statusList.forEach(x => {
                        service.getByStatus(x.id).then(contracts => {
                            const result = {
                                statusId: x.id,
                                contracts: contracts.data.data
                            };
                            this.loadingData = false;
                            this.contracts.push(result);
                        });
                    });
                    this.loading = false;
                })
            },
            goNew() {
                router.push({name: 'contract-select'})
            },
            getCurrentTab() {
                let result = 'tab-';
                if( this.$route.params.tab) {
                    const index = this.statusList.findIndex(x=> {
                        return x.nombre === this.$route.params.tab;
                    });
                    return index >=0 ? result += index: result += '0'
                }
                return 'tab-0'
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Documentos"}, {title: "Mis contratos"}
            ]);
            authService.getUserProfile().then(x => {
                this.user = x.data.data;
            });
            this.initData();

        },
        computed: {

        }
    }
</script>

<style scoped>
    .modal ::v-deep .modal-content {
        width: 800px !important;
    }
</style>
